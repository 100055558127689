import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { connect, useDispatch } from 'react-redux';
import * as actionCreators from '../../actions';
import { togglePromotionModalView } from '../../actions';
import { sendAccessToken } from '../../queries/mutations';
import { getLangDefinition } from '../../utils/helpers';
import AdblockerDetector from '../Banner/BlockerDetector';
import HeaderPlaceholder from '../Placeholders/HeaderPlaceholder';
import FooterPlaceholder from '../Placeholders/FooterPlaceholder';
import Loader from '../Loader';
import { useBridgeState } from '../../bridge';
import TabBarNavigationServer from '../TabBarNavigation/TabBarNavigationServer';
import useBackHistoryStorage from '../../hooks/useBackHistoryStorage';
import useScrollState from '../../hooks/useScrollState';
import Icon from '../Icon';
import Logo from '../../public/static/images/brown-logo.svg';
import Plus from '../../public/static/images/green-plus.svg';
import { MainLayout } from '../ui/Layout';
import { isCurrentItemPage } from '../../utils/helpers/common';
import {
  longPromotionHashes,
  shortPromotionHashes,
  urlHashes
} from '../../utils/helpers/constants';

const Banner = dynamic(() => import('../Banner'), {
  ssr: false
});

const AdManagerBanner = dynamic(() => import('../Banner/AdManagerBanner'), {
  ssr: false
});

const HeaderLight = () => (
  <div className="white-header white-header--main" id="white-header">
    <a data-stat="header-logo" href="/" tabIndex="-1">
      <Icon IconComponent={Logo} width={85} height={19} />
    </a>
    <a
      className="white-header__new-btn"
      href="/items/new"
      data-stat="header-new-btn"
    >
      <Icon IconComponent={Plus} width={30} height={30} indent={false} />
    </a>
  </div>
);

const TabBarNavigation = dynamic(() => import('../TabBarNavigation'), {
  loading: TabBarNavigationServer,
  ssr: false
});

const Footer = dynamic(() => import('../Footer'), {
  loading: FooterPlaceholder,
  ssr: false
});

const PromotionModalView = dynamic(() => import('../PromotionModalView'), {
  ssr: false
});

const SideMenuModal = dynamic(() => import('../SideMenuModal'), {
  ssr: false
});

const AuthModal = dynamic(() => import('../AuthModal'), {
  ssr: false
});

const mapStateToProps = state => {
  return {
    isVisible: state.drawerUI.isVisible,
    isSideMenuVisible: state.sideMenuModal?.isVisible,
    isPromotionModalView: state.promotionModalView?.isVisible,
    isAuthVisible: state?.authModal?.isVisible
  };
};

const Layout = ({
  isBanner,
  children,
  noBorder,
  setBlockerDetector,
  toggleSideMenuModal,
  isAuthVisible,
  isSideMenuVisible,
  isPromotionModalView,
  toggleAuthModal,
  headerTitle,
  isBottomNavigation = true,
  isShowHeader = true,
  isFixedHeader = false,
  isShowBackButton = false,
  isShowCloseButton = true,
  isHomePage = false
}) => {
  const {
    i18n: { language }
  } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const { isWebView } = useBridgeState();
  const isScroll = useScrollState();

  const closeAuthModalHandler = () => {
    toggleAuthModal(false);

    router.push(`${router.asPath.split('#')[0]}`, undefined, {
      shallow: true
    });
  };
  const accessToken = router?.query.access_token;
  const [mutateToken, { loading }] = useMutation(sendAccessToken);
  useBackHistoryStorage();

  useMemo(() => {
    if (accessToken) {
      mutateToken({
        variables: { accessToken }
      });
    }
  }, []);

  useEffect(() => {
    toggleAuthModal(!!router.asPath.match(urlHashes.authentication));
    toggleSideMenuModal(!!router.asPath.match(urlHashes.more));

    dispatch(
      togglePromotionModalView(
        shortPromotionHashes.some(hash => router.asPath.match(hash))
      )
    );

    router?.events.on('hashChangeComplete', url => {
      toggleAuthModal(!!url.match(urlHashes.authentication));
      toggleSideMenuModal(!!url.match(urlHashes.more));

      dispatch(
        togglePromotionModalView(
          !!longPromotionHashes.some(hash => url.match(hash))
        )
      );
    });
  }, []);

  useEffect(() => {
    if (isCurrentItemPage(router.asPath)) {
      window.scrollTo(0, 0);
    }
  }, [router.asPath]);

  const WhiteHeader = dynamic(() => import('../WhiteHeader'), {
    loading: isHomePage ? HeaderLight : HeaderPlaceholder,
    ssr: false
  });

  if (loading) return null;

  return (
    <MainLayout
      language={getLangDefinition(language)}
      {...{
        isScroll,
        isWebView
      }}
    >
      {isBanner && (
        <>
          <Banner
            key="lotriver-top-banner"
            containerClassName={'lotriver-top-banner'}
            containerId={'js-lotriver-top-banner'}
            sid={'220686'}
            bn={'2'}
            arCn={1}
          />

          <AdManagerBanner bannerName={'top'} />
        </>
      )}
      {isShowHeader && (
        <WhiteHeader
          {...{
            noBorder,
            headerTitle,
            isFixedHeader: isWebView || isFixedHeader,
            isShowBackButton,
            isShowCloseButton
          }}
        />
      )}
      <AdblockerDetector setBlockerDetector={setBlockerDetector} />
      {children}
      <Loader pageLoader={true} />
      {isBottomNavigation && <TabBarNavigation />}
      {!(isWebView || isSideMenuVisible) && (
        <Footer
          isTabBarExist={isBottomNavigation}
          isBackgroundWhite={!isShowHeader}
        />
      )}
      {isSideMenuVisible && <SideMenuModal />}
      {isPromotionModalView && <PromotionModalView />}
      {isAuthVisible && (
        <AuthModal
          isOpen={isAuthVisible}
          modalId="auth"
          closeHandler={closeAuthModalHandler}
        />
      )}
    </MainLayout>
  );
};

Layout.propTypes = {
  noBorder: PropTypes.bool,
  children: PropTypes.node,
  toggleDrawer: PropTypes.func,
  toggleAuthModal: PropTypes.func,
  isVisible: PropTypes.bool,
  isBanner: PropTypes.bool,
  isAuthVisible: PropTypes.bool,
  isSideMenuVisible: PropTypes.bool,
  isPromotionModalView: PropTypes.bool,
  setBlockerDetector: PropTypes.func,
  toggleSideMenuModal: PropTypes.func,
  isBottomNavigation: PropTypes.bool,
  isShowHeader: PropTypes.bool,
  isFixedHeader: PropTypes.bool,
  headerTitle: PropTypes.string,
  isShowBackButton: PropTypes.bool,
  isShowCloseButton: PropTypes.bool,
  isHomePage: PropTypes.bool
};

export default connect(mapStateToProps, actionCreators)(Layout);
