import React from 'react';

const HeaderPlaceholder = () => {
  return (
    <div className="placeholder-header color-white">
      <div className="placeholder-row placeholder-header__burger animation" />
      <div className="placeholder-row placeholder-header__logo animation" />
      <div className="placeholder-row placeholder-header__button animation" />
    </div>
  );
};

export default HeaderPlaceholder;
