import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import getTabBarItems from '../../utils/helpers/getTabBarItems';
import TabBarItemServer from './TabBarItemServer';

const TabBarNavigationServer = () => {
  const { t } = useTranslation();
  const tabBarItems = getTabBarItems(t);

  return (
    <div className="tab-bar-container">
      <div className="tab-bar">
        <ul className="tab-bar__list">
          {tabBarItems.map((item, i) => (
            <TabBarItemServer key={`item_${i}`} {...{ item }} />
          ))}
        </ul>
      </div>
    </div>
  );
};

TabBarNavigationServer.propTypes = {
  headerTitle: PropTypes.string
};

export default TabBarNavigationServer;
