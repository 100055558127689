import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const BlockerDetector = ({ setBlockerDetector }) => {
  const detectorElement = React.createRef();

  useEffect(() => {
    if (detectorElement.current?.clientHeight === 0) {
      setBlockerDetector(true);
    }
  }, []);

  return (
    <div
      id="banner_ad"
      ref={detectorElement}
      className="banner_ad"
      style={{ height: '1px', position: 'fixed', bottom: 0 }}
    />
  );
};

BlockerDetector.propTypes = {
  setBlockerDetector: PropTypes.func
};

export default BlockerDetector;
