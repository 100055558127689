import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { BackHistoryStorage } from '../utils/helpers/BackHistoryStorage';

const useBackHistoryStorage = () => {
  const router = useRouter();

  useEffect(() => {
    const isPageReloaded =
      window?.performance && window?.performance?.navigation?.type === 1;

    if (!isPageReloaded) {
      BackHistoryStorage.removeBackHistoryStorage();
    }

    router.events.on('routeChangeStart', () => {
      const initialPath = BackHistoryStorage.getBackHistoryStorageInitialPath();
      BackHistoryStorage.setBackHistoryStorage();
      if (!initialPath) {
        BackHistoryStorage.setBackHistoryStorageInitialPath(
          window?.location?.pathname
        );
      }
    });

    return () => {
      router.events.off('routeChangeStart', () => {
        BackHistoryStorage.removeBackHistoryStorage();
      });
    };
  }, [router.events]);

  useEffect(() => {
    const handleHistoryBackEvent = () => {
      const initialPath = BackHistoryStorage.getBackHistoryStorageInitialPath();

      if (initialPath === window?.location?.pathname) {
        BackHistoryStorage.removeBackHistoryStorage();
      }
    };

    window.addEventListener('popstate', handleHistoryBackEvent);

    return () => {
      window.removeEventListener('popstate', handleHistoryBackEvent);
    };
  }, []);
};

export default useBackHistoryStorage;
