import React from 'react';

const FooterPlaceholder = () => {
  return (
    <div className="placeholder-footer color-white">
      <div className="placeholder-footer__top">
        <div className="placeholder-footer__col placeholder-footer__col--1">
          <div className="placeholder-row placeholder-row--mt--10 placeholder-footer__top-title animation" />
        </div>
        <div className="placeholder-footer__col placeholder-footer__col--2">
          <div className="placeholder-row placeholder-row--mt--10 placeholder-footer__top-title animation" />
          <div className="placeholder-row placeholder-row--mt--10 placeholder-footer__top-contacts animation" />
          <div className="placeholder-row placeholder-row--mt--10 placeholder-footer__top-contacts animation" />
        </div>
        <div className="placeholder-footer__col placeholder-footer__col--2">
          <div className="placeholder-row placeholder-row--mt--10 placeholder-footer__top-title animation" />
          <div className="placeholder-row placeholder-row--mt--10 placeholder-footer__top-contacts  animation" />
        </div>
      </div>
      <div className="placeholder-footer__bottom">
        <div className="placeholder-row placeholder-row--mt--10 placeholder-footer__bottom-agreement animation" />
        <div className="placeholder-row placeholder-row--mt--10  placeholder-footer__bottom-map animation" />
        <div className="placeholder-row placeholder-row--mt--10  placeholder-footer__bottom-advertising animation" />
        <div className="placeholder-row placeholder-row--mt--10 placeholder-footer__bottom-full animation" />
        <div className="placeholder-row placeholder-row--mt--10 placeholder-footer__bottom-copyright animation" />
      </div>
    </div>
  );
};

export default FooterPlaceholder;
