import styled from 'styled-components';

export const FlexibleDiv = styled.div`
  display: flex;
`;

export const FlexCenterAligned = styled(FlexibleDiv)`
  align-items: center;
  justify-content: center;
`;

export const FlexColumn = styled(FlexibleDiv)`
  flex-direction: column;
`;

export const FlexCenteredColumn = styled(FlexColumn)`
  align-items: center;
`;
