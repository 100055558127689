import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Icon from '../Icon';

const TabBarItemServer = ({ item }) => {
  const {
    path,
    name,
    icon,
    text,
    isLink,
    isDefaultLink,
    iconW,
    iconH,
    isProfileTab
  } = item;

  const onClickDivContainerHandler = () => {};

  const tabBarItemContent = (
    <>
      <i className={'tab-bar__icon'}>
        <Icon
          indent={false}
          width={iconW}
          height={iconH}
          color={name === 'home' ? 'dark' : 'brown'}
          IconComponent={icon}
        />
      </i>
      <span className="tab-bar__link-text">{text}</span>
    </>
  );

  return (
    <li className="tab-bar__list-item">
      {(() => {
        switch (true) {
          case isProfileTab:
            return (
              <div
                role="button"
                onKeyPress={onClickDivContainerHandler}
                onClick={onClickDivContainerHandler}
                className={'tab-bar__link'}
                tabIndex={0}
                data-stat="tab-bar-login-link"
              >
                {tabBarItemContent}
              </div>
            );
          case isDefaultLink:
            return (
              <a
                className="tab-bar__link tab-bar__link--centered"
                href="/items/new"
                data-stat="new-item-bar-link"
              >
                {tabBarItemContent}
              </a>
            );
          case isLink:
            return (
              <Link
                href={path}
                className={'tab-bar__link'}
                data-stat={`${name}-bar-link`}
              >
                {tabBarItemContent}
              </Link>
            );
          default:
            return (
              <div
                role="button"
                onKeyPress={onClickDivContainerHandler}
                onClick={onClickDivContainerHandler}
                className={'tab-bar__link'}
                tabIndex={0}
                data-stat={`${name}-bar-button`}
              >
                {tabBarItemContent}
              </div>
            );
        }
      })()}
    </li>
  );
};

TabBarItemServer.propTypes = {
  item: PropTypes.object
};

export default TabBarItemServer;
