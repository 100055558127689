import HomeIcon from '../../public/static/images/tab_bar/home.svg';
import BookmarksIcon from '../../public/static/images/tab_bar/bookmarks.svg';
import PlusIcon from '../../public/static/images/tab_bar/plus.svg';
import ProfileIcon from '../../public/static/images/tab_bar/cabinet.svg';
import HamburgerIcon from '../../public/static/images/tab_bar/hamburger.svg';

const getTabBarItems = t => {
  return [
    {
      name: 'home',
      path: '/',
      routes: ['/', '/[...search]'],
      icon: HomeIcon,
      text: t('pages.tab_bar.buttons.real_estate'),
      isLink: true,
      eraseData: true,
      iconW: 21,
      iconH: 20
    },
    {
      name: 'bookmarks',
      path: '/bookmarks',
      routes: ['/bookmarks'],
      icon: BookmarksIcon,
      text: t('pages.tab_bar.buttons.bookmarks'),
      isLink: true,
      iconW: 24,
      iconH: 21
    },
    {
      name: 'new-item',
      path: '',
      routes: [],
      icon: PlusIcon,
      text: t('pages.tab_bar.buttons.add'),
      isAddNew: true,
      iconW: 45,
      iconH: 45
    },
    {
      name: 'profile',
      path: '',
      routes: [],
      icon: ProfileIcon,
      text: t('pages.tab_bar.buttons.cabinet'),
      isProfileTab: true,
      iconW: 20,
      iconH: 20
    },
    {
      name: 'more',
      path: '',
      routes: [],
      icon: HamburgerIcon,
      text: t('pages.tab_bar.buttons.more'),
      isSideMenuTab: true,
      iconW: 26,
      iconH: 20
    }
  ];
};

export default getTabBarItems;
